import {
  Messaging,
  getMessaging,
  getToken,
  onMessage,
} from 'firebase/messaging';
import { getFCMDevice, setFCMDevice } from './localStorageHandler/fcmDevice';

import { FirebaseApp } from 'firebase/app';
import { UserService } from 'app/services/user';
import environment from 'app/config/environment';
import { message } from 'antd';

/**
 * Get the device ID for FCM
 * If the device ID is not available, generate a new one
 * and store it in the local storage
 *
 * @returns {string} The device ID
 */
const getDeviceId = () => {
  let deviceId = getFCMDevice();
  if (!deviceId) {
    deviceId = 'device_' + new Date().getTime();
    setFCMDevice(deviceId);
  }
  return deviceId;
};

/**
 * Request permission to send notifications
 * If permission is granted, show a success message
 * Otherwise, log an error message
 *
 * @returns {Promise<void>}
 */
const requestFCMPermission = async () => {
  console.log('Requesting permission...');
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
  } else {
    console.log('Unable to get permission to notify.');
  }
};

/**
 * Register the FCM service worker
 *
 * @param {Messaging} messaging The Firebase Messaging instance
 * @returns {Promise<void>}
 */
const registerFCMServiceWorker = async () => {
  // Register the service worker
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        '/firebase-messaging-sw.js'
      );
      console.log(
        'FCM Service Worker registered with scope:',
        registration.scope
      );
    } catch (error) {
      console.error('Error registering service worker:', error);
    }
  }
};

/**
 *
 * Get the FCM token and register it with the user
 *
 * @param messaging Firebase Messaging instance
 * @returns {Promise<string | void>}
 *
 */
const getFCMToken = async (messaging: Messaging) => {
  try {
    // After registration, get the FCM token and log it to the console
    const vapidKey = environment.firebase.FIREBASE_VAPID_KEY;
    const currentToken = await getToken(messaging, {
      vapidKey: vapidKey,
    });
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      // Send the token to your server and update the UI if necessary
      return currentToken;
    } else {
      console.log(
        'No registration token available. Request permission to generate one.'
      );
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

const registerUserFCMToken = async (fcmToken: string) => {
  const device = getDeviceId();
  const userSvc = new UserService();
  await userSvc.registerUserFCMToken(fcmToken, device);
};

/**
 * Initialize Firebase Cloud Messaging (FCM)
 * Request permission, register the service worker, get the FCM token and register it with the user
 * Also, listen for incoming messages
 * Customize the notification here
 *
 * @param firebaseApp The Firebase app instance
 * @returns {Promise<void>}
 */
export const initializeFCM = async (firebaseApp: FirebaseApp) => {
  await requestFCMPermission();

  const messaging = getMessaging(firebaseApp);
  const fcmToken = await getFCMToken(messaging);
  if (!fcmToken) {
    return;
  }

  await registerUserFCMToken(fcmToken);

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);

    const notificationTitle = payload.notification?.title || 'MaxHome';
    const notificationOptions = {
      body: payload.notification?.body,
      // icon: payload.notification?.icon,
    };

    if (Notification.permission === 'granted') {
      new Notification(notificationTitle, notificationOptions);
    }

    // Customize notification here
    message.info(
      `${payload.notification?.title}: ${payload.notification?.body}`
    );
  });

  await registerFCMServiceWorker();
};
